import AboutUs from '../components/AboutUs';
import ContactUs from '../components/ContactUs';
import Hero from '../components/Hero';

const HomePage = (props) => {
  return (
    <div className=''>
      <Hero />
      <main className='max-w-6xl mx-auto px-8 sm:px-16 mb-10'>
        <AboutUs />
        <ContactUs />
      </main>
    </div>
  );
};

export default HomePage;
