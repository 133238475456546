function AboutUs() {
  return (
    <div className='pt-9'>
      <p className='text-red-500 text-3xl font-semibold mb-10'>About Us</p>
      <div className='flex flex-col items-center'>
        <div className='flex space-x-10 mb-5'>
          <h3 className='text-red-500 font-semibold'>01</h3>
          <p>
            Austin AI Europe is the European representation of the sensor-based automated sorting
            system innovator and producer,{' '}
            <a
              href='https://austinai.com'
              target='_blank'
              className='text-red-500'
              rel='noreferrer'
            >
              {' '}
              Austin AI Inc.
            </a>{' '}
            (est. 2001).
          </p>
        </div>
        <div className='flex space-x-10 mb-5'>
          <p className='text-red-500 font-semibold'>02</p>
          <p>
            Up to now, Austin AI Inc. is the only manufacturer who has the IP of all the most
            modern, chemistry based sensor sorters, like LIBS, XRF, THz. These techniques help
            recycling companies to save energy and lower their carbon footprint. Providing our
            innovative technologies to the waste management industry we contribute to the circular
            economy goals. We are proud of being part of the global protection of our environment.
          </p>
        </div>
        <div className='flex space-x-10 mb-5'>
          <p className='text-red-500 font-semibold'>03</p>
          <p>
            Thanks to the advanced environmental regulatory power of Europe, Austin AI sensor
            sorters are gaining more and more interest in Europe. And beyond interest also gaining
            increasing numbers of reference sites with full satisfaction of the users.
          </p>
        </div>
        <div className='flex space-x-10 mb-5'>
          <p className='text-red-500 font-semibold'>04</p>
          <p>
            Austin AI Europe is responsible for the European sales and marketing as well as provides
            service and maintenance support for EMEA customers. You are also welcome to visit our
            demo & test facility in Budapest.
          </p>
        </div>
        <div className='flex space-x-10 mb-5'>
          <p className='text-transparent font-semibold'>05</p>
          <p>
            Please complete the form below, so we may send you more information on our products of
            your interest. You can expect a response from our customer service team within 48 hours.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
