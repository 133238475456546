import {useState, createContext} from 'react';

const AdminContext = createContext();

const AdminProvider = (props) => {
  const [isLoggedIn, setLoggedIn] = useState(false);

  const validateToken = () => {
    const token = localStorage.getItem('x-access-token');
    if (!token) {
      return false;
    }
    // TODO: implement proper backend validation
    // if new token received, overwrite local storage

    return true;
  };

  const logout = () => {
    localStorage.removeItem('x-access-token');
    setLoggedIn(false);
  };

  return (
    <AdminContext.Provider value={{isLoggedIn, setLoggedIn, validateToken, logout}}>
      {props.children}
    </AdminContext.Provider>
  );
};

export {AdminContext, AdminProvider};
