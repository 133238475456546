import {NavLink} from 'react-router-dom';

const NavItem = ({item, toggleHidden, ...rest}) => {
  return (
    <NavLink
      to={item.url}
      exact={item.title === 'Home' ? true : false}
      className='uppercase hover:text-black'
      activeClassName='text-black'
      onClick={toggleHidden}
      key={item.title + 'Burger'}
    >
      {item.title}
    </NavLink>
  );
};

export default NavItem;
