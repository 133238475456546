import {Link} from 'react-router-dom';
const NotFound = () => {
  return (
    <div className='flex flex-col flex-1 justify-center items-center'>
      <h2>404 Error</h2>
      <p>Page not found!</p>
      <Link to='/'>Back to the homepage!</Link>
    </div>
  );
};

export default NotFound;
