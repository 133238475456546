const Footer = () => {
  return (
    <footer className='flex justify-center w-full bg-gray-100'>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-y-10 px-32 py-10 md:w-full 2xl:w-3/5 my-auto'>
        <div className='md:flex md:justify-center'>
          <div className=''>
            <h3 className='font-bold'>Austin AI - Europe</h3>
            <a
              href='https://www.linkedin.com/company/austin-ai-europe/'
              className='text-blue-500 font-semibold'
              target='_blank'
              rel='noreferrer'
            >
              LinkedIn
            </a>
          </div>
        </div>
        <div className='md:flex md:justify-center'>
          <div className=''>
            <h5 className='font-bold'>Address:</h5>
            <p>Tóberek u. 8</p>
            <p>1112 Budapest, Hungary</p>
            <p className='font-bold pt-3'>Test Facility:</p>
            <p>Helsinki u. 128</p>
            <p>1238 Budapest, Hungary</p>
          </div>
        </div>

        <div className='md:flex md:justify-center'>
          <div className=''>
            <h5 className='font-bold'>Contact:</h5>
            <p>Email: info@austinai-eu.com </p>
            <p>Phone: +36 309 545 146</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
