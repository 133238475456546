import {useState} from 'react';
import './Navbar.css';
import {MenuIcon} from '@heroicons/react/outline';
import {NavLink, Link} from 'react-router-dom';
import NavItem from './NavItem';

/**
 * TODO:
 * Create a new NavLink with inactiveClassName props
 * based on this video: https://www.youtube.com/watch?v=F5eDWtJRYaI
 */

const Navbar = (props) => {
  const [hiddenState, setHidden] = useState(true);

  const toggleHidden = () => {
    setHidden(!hiddenState);
  };

  const navItems = [
    {title: 'Home', url: '/'},
    {title: 'Products', url: '/products'},
    {title: 'Applications', url: '/applications'},
    {title: 'Testing Facility', url: '/testfacility'},
    {title: 'News & Media', url: '/news'},
  ];

  return (
    <div className='sticky top-0 z-50'>
    <nav className='sticky top-0 z-50 grid grid-cols-5 shadow-md py-5 px-5 bg-white md:px-10'>
      <div className='flex items-center h-12 my-auto'>
        <NavLink to='/'>
          <img src='/images/logo.png' alt='' className='cursor-pointer navbar__logo' />
        </NavLink>
      </div>
      <div className='hidden md:flex items-center justify-end space-x-5 text-gray-400 col-span-4'>
        {props.isLoggedIn && (
          <>
            <NavLink to='/' className='uppercase hover:text-black' onClick={props.logout}>
              Logout
            </NavLink>
            <NavLink
              exact
              to='/dashboard'
              className='uppercase hover:text-black'
              activeClassName='text-black'
              key='dashboard'
            >
              Dashboard
            </NavLink>
          </>
        )}
        {navItems.map((item) => {
          return <NavItem item={item} />;
        })}
      </div>
      <div className='md:hidden flex justify-end col-span-4 cursor-pointer'>
        <MenuIcon className='h-10 w-10 text-gray-600' onClick={toggleHidden} />
      </div>
      <div className={`${hiddenState ? 'hidden' : ''} md:hidden mobile-menu pt-5`}>
        <ul>
          {navItems.map((item) => {
            return (
              <li>
                <NavItem item={item} toggleHidden={toggleHidden} />
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
      {/*
    <div className=' w-full block bg-red-400 py-2 text-right whitespace-nowrap overflow-hidden'>
        <p className='animate-scrollText inline-block'>
          Austin AI Europe will be exhibiting at the IFAT Munich Trade Fair 2022. Visit us at C5/116.
          <Link to={{ pathname: 'https://exhibitors.ifat.de/en/exhibitors-products/exhibitors-brand-names/exhibitors-details/ID/1156127/action/detail/controller/Exhibitors/'}} target="_blank">
            {' '}
            <span className='text-white'>More info.</span>{' '}
          </Link>
        </p>
      </div>
    */}
    </div>
  );
};

export default Navbar;
