import './Hero.css';

const Hero = () => {
  return (
    <div className="relative h-[600px] hero">
      <img
        src="/images/landing_bg.jpg"
        alt=""
        className="opacity-50 hero__bg"
      />
      <div className="flex flex-col md:flex-row absolute w-full h-full justify-center md:gap-x-5">
        <div className="text-center md:text-right my-auto mx-auto md:mx-0">
          <img
            src="/images/rectangles.png"
            alt=""
            className="hero__rectangle"
          />
        </div>
        <div className="flex flex-col my-auto md:w-1/2 lg:w-1/3 mx-5">
          <p className="text-3xl text-center md:text-left ">
            The <b>Texas Star</b> of new technology development for all
            recycling and scrap stream sorters.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
