import {useContext, useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AdminLayout from './layouts/AdminLayout';
import {AdminContext} from './context/AdminContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import HomePage from './pages/HomePage';
import ProtectedRoute from './components/utils/ProtectedRoute';
import AdminDashboard from './pages/admin/AdminDashboard';
import Products from './pages/Products';
import Solutions from './pages/Solutions';
import TestFacility from './pages/TestFacility';
import News from './pages/News';
import NotFound from './pages/NotFound';

function App() {
  const {isLoggedIn, logout, validateToken, setLoggedIn} = useContext(AdminContext);

  useEffect(() => {
    if (validateToken()) {
      setLoggedIn(true);
    }
  }, [isLoggedIn, setLoggedIn, validateToken]);

  return (
    <Router>
      <div className='App flex flex-1 flex-col font-sans min-h-screen'>
        {/* Navbar */}
        <Navbar isLoggedIn={isLoggedIn} logout={logout} />
        {/* Router items? */}
        <div className='content flex flex-1 flex-col'>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route path='/products' render={(props) => <Products isLoggedIn={isLoggedIn} />} />
            <Route path='/applications' render={(props) => <Solutions isLoggedIn={isLoggedIn} />} />

            <Route
              path='/testfacility'
              render={(props) => <TestFacility isLoggedIn={isLoggedIn} />}
            />
            <Route path='/news' render={(props) => <News isLoggedIn={isLoggedIn} />} />
            {/* <Route path='/admin' component={AdminLayout}></Route>
            <ProtectedRoute path='/dashboard'>
              <AdminDashboard />
            </ProtectedRoute> */}
            <Route path='*' component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
