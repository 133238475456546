import {useState, useRef, useContext} from 'react';
import {useHistory} from 'react-router';
import {GlobalContext} from '../../context/GlobalContext';
import JoditEditor from 'jodit-react';
import {post} from 'axios';
import './Editor.css';
//import sanitizeHtml from 'sanitize-html';

const EditProduct = (props) => {
  const {refreshProducts} = useContext(GlobalContext);
  const history = useHistory();
  const editor = useRef(null);
  const [content, setContent] = useState(
    props.product !== undefined ? props.product.descriptionLong : '',
  );
  const [title, setTitle] = useState(props.product !== undefined ? props.product.title : '');
  const [shortDescription, setShortDescription] = useState(
    props.product !== undefined ? props.product.description : '',
  );
  const [previewImage, setPreviewImage] = useState(
    props.product !== undefined ? props.product.img_url : '',
  );
  const [generalInfo, setGeneralInfo] = useState(
    props.product !== undefined ? props.product.generalInfo : '',
  );
  const [uploadedImage, setUploadedImage] = useState(null);
  const [newImage, setNewImage] = useState(false);

  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: true,
    uploader: {
      url: '/api/products/upload',
      process: function (res) {
        return res;
      },
      defaultHandlerSuccess: function (res) {
        this.j.selection.insertImage(res.file, null, 250);
      },
    },
    style: {
      height: '600px',
    },
    editorCssClass: 'editor-class',
  };

  const cancelEdit = () => {
    history.push('/dashboard');
  };

  const saveProduct = () => {
    const data = new FormData();
    if (newImage) {
      data.append('img', uploadedImage);
    }
    data.append('title', title);
    data.append('descriptionLong', content);
    data.append('description', shortDescription);
    data.append('generalInfo', generalInfo);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    if (props.new) {
      post('/api/products', data, config).then((res) => {
        if (res.status === 200) refreshProducts();
      });
    } else {
      post(`/api/products/${props.product._id}`, data, config).then((res) => {
        if (res.status === 200) refreshProducts();
      });
    }
    history.push('/dashboard');
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setNewImage(true);
      setUploadedImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPreviewImage(reader.result);
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      <div className='flex flex-col flex-1 mx-28 mt-10'>
        {previewImage && <img src={previewImage} alt='' width='300' height='300' />}
        <input type='file' onChange={(event) => onChangePicture(event)} />
        <label htmlFor='' className='w-full p-2 bg-gray-100'>
          Title
        </label>
        <input
          type='text'
          value={title}
          className='my-1 py-2 px-2 border rounded-sm'
          onChange={(e) => setTitle(e.target.value)}
        />
        <label htmlFor='' className='w-full p-2 bg-gray-100'>
          Short Description
        </label>
        <input
          type='text'
          maxLength='150'
          value={shortDescription}
          className='my-1 py-2 px-2 border rounded-sm'
          onChange={(e) => setShortDescription(e.target.value)}
        />
        <label htmlFor='' className='w-full p-2 bg-gray-100'>
          General Info
        </label>
        <input
          type='text'
          value={generalInfo}
          className='my-1 py-2 px-2 border rounded-sm'
          maxLength='400'
          onChange={(e) => setGeneralInfo(e.target.value)}
        />
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          tabIndex={1}
          onBlur={(newContent) => setContent(newContent)}
          onChange={(newContent) => {}}
        />
      </div>
      <div className='flex justify-center my-9'>
        <button
          className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-1'
          onClick={saveProduct}
        >
          Save
        </button>
        <button
          className='bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-4 rounded ml-1'
          onClick={(event) => {
            event.preventDefault();
            cancelEdit();
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default EditProduct;
