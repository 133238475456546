import './ProductCard.css';
import {Link} from 'react-router-dom';

const ProductCard = (props) => {
  return (
    <div className='my-10 mx-5 product-card relative'>
      <div className='flex flex-col product-card__side shadow-md'>
        <div className='' style={{height: '200px'}}>
          <img src={props.img_url} className='object-cover product-card__image' alt='' />
        </div>
        <div className='px-5 py-5 product-card__description bg-gray-200'>
          <p className='uppercase font-semibold text-xl text-gray-700'>{props.title}</p>
          <p className='break-word'>{props.description}</p>
        </div>
      </div>
      <div className='product-card__side__back bg-gray-200 shadow-md pt-3 pb-3 px-5 relative'>
        <p className='font-bold'>{props.title} BASICS</p>
        <div className=''>{props.generalInfo}</div>
        <Link to={`/products?p=${props.productId}`}>
          <p className='text-red-500 inline-block absolute bottom-3 right-5'>
            More about the product
          </p>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
