import {useState, useEffect} from 'react';
import axios from 'axios';

const Solutions = (props) => {
  // const [solutions, setSolutions] = useState([] | '');

  // useEffect(() => {
  //   axios.get('/api/solutions').then((res) => {
  //     setSolutions(res.data);
  //   });
  // }, [solutions, setSolutions]);

  return (
    <div className='flex flex-col flex-1 items-center'>
      <h1 className='font-bold uppercase my-5'>LIBS Applications</h1>
      <ul className='list-disc list-inside space-y-3'>
        <li>Extraction of Mg Alloy from Mixed Light Fraction (Twitch/Zorba)</li>
        <li>Sorting Scrap Wrought Al Alloys from Scrap Cast Al Alloys (Twitch/Zorba)</li>
        <li>Sorting 6xxx from 5xxx series Al Alloy Scrap</li>
        <li>Extracting 7xxx from Shredded Old Rolled</li>
        <li>Extracting 6082 from Profiles Scrap</li>
        <li>Extracting High Cu Cast Al Alloy from Low Cu Cast Al Alloy</li>
        <li>Sorting 6082 and 7xxx series in mixed Al Wrought Scrap</li>
        <li>Sorting 6082 from 6063 in Mixed Al Wrought Scrap</li>
        <li>Extracting Cast Al Alloy from Shredded Old Rolled</li>
        <li>Extracting High Fe Cast Al Alloy from Low Fe Cast Al Alloy</li>
        <li>Sorting Zorba heavies by alloy class: Cu, Brass, SS, Zn, etc.</li>
        <li>Extracting 1xxx series from mixed Al scrap</li>
      </ul>
    </div>
  );
};

export default Solutions;
