import {useContext} from 'react';
import {Redirect, Link, useLocation} from 'react-router-dom';
import Product from './Product';
import ProductCard from '../components/ProductCard';
import {AdminContext} from '../context/AdminContext';
import {GlobalContext} from '../context/GlobalContext';
import EditProduct from '../components/admin/EditProduct';

const Products = (props) => {
  const {productState, getProductFromCtx} = useContext(GlobalContext);
  const {isLoggedIn} = useContext(AdminContext);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const p = query.get('p');
  const edit = query.get('edit') === '1' && isLoggedIn ? 1 : 0;
  const _newProduct = query.get('new') && isLoggedIn ? 1 : 0;
  const queryKeys = Array.from(query.keys());

  const getComponentToRender = () => {
    if (!queryKeys.length || (!p && !edit && !_newProduct)) {
      return (
        <div className='flex flex-col items-center flex-1'>
          <h1 className='font-bold my-5 uppercase'>Products</h1>
          <p className='px-10 md:px-0 md:w-1/2'>
            There are three key products currently in — or ready to join — the AAI intellectual
            property list. In general, most of AAI’s technology covers the broad range of products
            known as automated sensor-based sorters. That is, they are designed to take a
            multi-faceted product or waste stream, and render the contents more attractive to
            recyclers or re-users by minimizing the amount of work/energy required for re-use.
          </p>
          <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5`}>
            {productState.map((product) => {
              return (
                <ProductCard
                  img_url={product.img_url}
                  title={product.title}
                  key={product.title}
                  description={product.description}
                  productId={product._id}
                  generalInfo={product.generalInfo}
                />
              );
            })}
          </div>
        </div>
      );
    } else if (!edit && !_newProduct && p) {
      return <Product id={p} />;
    } else if (edit && p && !_newProduct) {
      return <EditProduct id={p} product={getProductFromCtx(p)} />;
    } else if (_newProduct && !edit && !p) {
      return <EditProduct new={true} />;
    } else {
      // Utter bullshit, redirect to homepage
      return <Redirect to='/' />;
    }
  };

  return <>{getComponentToRender()}</>;
};

export default Products;
