import {useContext, useEffect} from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import {GlobalContext} from '../context/GlobalContext';

const TestFacility = (props) => {
  const {productState} = useContext(GlobalContext);

  let id;
  productState.forEach((product) => {
    if (product.title.toLowerCase() === 'libs') {
      id = product._id;
    }
  });

  return (
    <div className='flex flex-col flex-1 items-center'>
      <h1 className='mx-auto font-bold my-5 uppercase'>TESTING FACILITY</h1>
      <div className='px-10 md:px-0 md:w-1/2'>
        <p className='mb-5'>
          Since January 1, 2021 Austin AI Europe has been running a LIBS test facility in{' '}
          <span className='font-bold'>Budapest</span> (Hungary) to demonstrate the versatility of
          Austin AI's unique{' '}
          <Link className='text-red-400' to={{pathname: '/products', search: `?p=${id}`}}>
            LIBS sensor sorter
          </Link>
          .
        </p>
        <div className='flex flex-1 place-content-center'>
          <img src='/images/libs.png' alt='' />
        </div>
        <p className='my-5'>
          We welcome everyone who is involved in metal recycling and/or in secondary Aluminium
          smelter business. Also, those who are interested in the precise Aluminium scrap sorting or
          who want to produce special Aluminum alloys with the help of the Best Available Technique
          (BAT), the chemistry based LIBS sensor sorting technology.
        </p>
        <p className='mb-5'>
          <Link className='text-red-400' to={{pathname: '/', hash: '#contactUs'}}>
            Reach out to us
          </Link>{' '}
          and schedule an on-site visit.
        </p>
      </div>
    </div>
  );
};

export default TestFacility;
