import './ContactUs.css';
import axios from 'axios';
import {useState} from 'react';
import * as sanitizeHtml from 'sanitize-html';

const ContactUs = () => {
  const [state, setState] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const [messageSent, setMessageSent] = useState(false);

  const handleData = (e) => {
    var prevState = {...state};
    prevState[e.target.id] = e.target.value;
    setState(prevState);
  };

  const resetState = () => {
    setState({fullName: '', email: '', message: ''});
  };

  const sendData = (e) => {
    e.preventDefault();
    // TODO: make this more beautiful
    // sanitize inputs
    var prevState = {...state};
    prevState.fullName = sanitizeHtml(prevState.fullName);
    prevState.message = sanitizeHtml(prevState.message);
    prevState.email = sanitizeHtml(prevState.email);
    setState(prevState);

    axios.post('/api/email/send', state).then((res) => {
      if (res.status === 200) {
        resetState();
        setMessageSent(true);
        setTimeout(() => {
          setMessageSent(false);
        }, 5000);
      }
    });
  };

  return (
    <section id='contactUs'>
      <div className=''>
        <div className='mt-5 mb-9'>
          <p className='text-red-500 text-3xl font-semibold'>Contact Us</p>
        </div>
        {messageSent && (
          <div
            className='bg-green-200 py-3 w-full text-center my-3 rounded-lg text-gray-600 cursor-pointer'
            onClick={() => setMessageSent(false)}
          >
            <h1>Message Sent!</h1>
          </div>
        )}
        <div className='flex flex-col md:flex-row md:gap-x-10'>
          <div className='relative md:w-1/2 my-6 sm:my-auto w-2/3 self-center'>
            <img src='/images/envelope.svg' alt='' />
          </div>
          <form className='md:w-1/2 mx-auto md:mx-0' onSubmit={(event) => sendData(event)}>
            <div>
              <span className='uppercase text-sm text-gray-600 font-bold'>Full Name</span>
              <input
                className='w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                type='text'
                placeholder=''
                value={state.fullName}
                id='fullName'
                onChange={(event) => handleData(event)}
              />
            </div>
            <div className='mt-8'>
              <span className='uppercase text-sm text-gray-600 font-bold'>Email</span>
              <input
                className='w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                type='email'
                id='email'
                value={state.email}
                onChange={(event) => handleData(event)}
              />
            </div>
            <div className='mt-8'>
              <span className='uppercase text-sm text-gray-600 font-bold'>Message</span>
              <textarea
                className='contact__textarea w-full h-80 bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                onChange={(event) => handleData(event)}
                value={state.message}
                id='message'
              ></textarea>
            </div>
            <div className='mt-8'>
              <button className='uppercase text-sm font-bold tracking-wide bg-red-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline'>
                Send Message
              </button>
            </div>
            <div className='mt-4'>
              <p>
                By sending the message, I understand that my data will be used for the hereby
                related business purpose.
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
