import {Link} from 'react-router-dom';
const News = (props) => {
  return (
    <div className='flex flex-1 flex-col items-center'>
      <h1 className='font-bold uppercase my-5'>News & Media</h1>
      <div className='container md:w-1/2 px-5 2xl:w-2/5'>
        Austin AI will be exhibiting at the ISRI 2022 convention & exposition, held this year, March
        21-24 at the Mandalay Bay Resort & Casino in Las Vegas, Nevada. Come check us out! (
        <Link
          to={{pathname: '/public/documents/aai_invitation_to_isri2022.pdf'}}
          target='_blank'
          className='text-blue-500'
        >
          link
        </Link>
        ). <span className='font-bold'>@booth 1110</span>.
        <div className='flex flex-1 place-content-center my-5'>
          <img src='/images/isri_banner_pc.png' alt='' />
        </div>
      </div>
    </div>
  );
};

export default News;
