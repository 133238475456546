import {useEffect, useState, createContext} from 'react';
import axios from 'axios';
import * as lodash from 'lodash';

const GlobalContext = createContext();

const GlobalProvider = (props) => {
  const [productState, setProducts] = useState([]);
  const [emptyProducts, setEmptyProducts] = useState(true);
  const [newsState, setNews] = useState([]);
  const [testFacilityState, setTestFacility] = useState([]);

  const refreshProducts = async () => {
    const res = await axios.get('/api/products');
    const products = await res.data;
    if (products.length > 0) {
      setProducts(products);
    } else {
      setEmptyProducts(true);
    }
  };

  const getProductFromCtx = (id) => {
    let product;
    productState.forEach((_product, idx) => {
      if (_product._id.toString() === id.toString()) {
        product = _product;
      }
    });

    if (product) {
      return product;
    } else {
      throw new Error('Product not found!');
    }
  };

  useEffect(() => {
    axios.get('/api/products/').then((res) => {
      const products = res.data;
      if (products.length > 0) {
        setProducts(products);
        setEmptyProducts(false);
      }
    });
  }, [setProducts, setEmptyProducts]);

  return (
    <>
      {(productState.length > 0 || emptyProducts) && (
        <GlobalContext.Provider
          value={{
            productState,
            setProducts,
            newsState,
            setNews,
            getProductFromCtx,
            refreshProducts,
          }}
        >
          {props.children}
        </GlobalContext.Provider>
      )}
    </>
  );
};

export {GlobalContext, GlobalProvider};
