import {useState, useContext, useEffect} from 'react';
import {GlobalContext} from '../context/GlobalContext';
import './Product.css';
// here, parse the url param and fetch the data to that product
// maybe use the global store?
const Product = ({id, ...rest}) => {
  const {productState} = useContext(GlobalContext);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    productState.forEach((_product, idx) => {
      if (_product._id.toString() === id.toString()) {
        setProduct(_product);
      }
    });
  }, [productState, id, setProduct]);

  return (
    <div className='flex flex-row flex-1 p-10 justify-center'>
      {product && (
        <div className='product-container'>
          <h1 className='text-center mb-5 font-bold'>{product.title}</h1>
          <div className='' dangerouslySetInnerHTML={{__html: product.descriptionLong}}></div>
        </div>
      )}
    </div>
  );
};

export default Product;
